<template>
  <div>
    <DataTable
      :value="mockedList2.details.table.data"
      tableStyle="min-width: 50rem"
    >
      <ColumnGroup type="header">
        <Row>
          <Column header="Basileia" :rowspan="2" />
          <Column
            v-for="col in mockedList2.details.table.columns"
            :key="col"
            :header="col"
            :colspan="mockedList2.details.table.subColumns.length"
          />
        </Row>
        <Row>
          <Column
            v-for="subcol in getSubCols()"
            :key="subcol.header"
            :header="subcol.header"
            :field="subcol.field"
          />
        </Row>
      </ColumnGroup>
      <Column field="baselLevel" />
      <Column
        v-for="subcol in getSubCols()"
        :key="subcol.header"
        :field="subcol.field"
      />
    </DataTable>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mockedList2: {
        id: 1,
        eventCount: 10,
        totalLossAmount: 5000.0,
        status: 1,
        attachments: [443, 444],
        details: {
          table: {
            columns: ["2023", "2024", "2025"],
            subColumns: ["count", "amount"],
            data: [
              {
                baselLevel: 1,
                2023: {
                  count: 101,
                  amount: 1001,
                },
                2024: {
                  count: 201,
                  amount: 2001,
                },
                2025: {
                  count: 301,
                  amount: 3001,
                },
              },
              {
                baselLevel: 2,
                2023: {
                  count: 102,
                  amount: 1002,
                },
                2024: {
                  count: 202,
                  amount: 2002,
                },
                2025: {
                  count: 302,
                  amount: 3002,
                },
              },
            ],
          },
        },
        registeredBy: "iude.meneses@btgpactual.com",
        createdTimestamp: "2020-06-18T17:24:53Z",
        error: {
          message: "",
          errors: [""],
        },
      },
    };
  },
  mounted() {
  },
  methods: {
    getSubCols() {
      let subcols = [];
      this.mockedList2.details.table.columns.forEach((col) => {
        this.mockedList2.details.table.subColumns.forEach((subCol) => {
          subcols.push({ field: col + "." + subCol, header: subCol });
        });
      });
      return subcols;
    },
  },
};
</script>
<style></style>